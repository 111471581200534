import React, {useState} from "react";
import {CheckIcon, CrossCircledIcon, InfoCircledIcon} from '@radix-ui/react-icons';
import {usePost} from "../../utils/usePost";
import {useShoppingCart} from "../../utils/ListContext";
import './ProceedList.css';
import {Link} from "react-router-dom";
import * as Checkbox from '@radix-ui/react-checkbox';
import * as Label from '@radix-ui/react-label';
import {useDocumentTitle} from "../../utils/useDocumentTItle";
import Loader from "../../components/loader/loader";

export const ProceedList: React.FC = () => {

    useDocumentTitle("Wyślij listę - Katalog Części Kubota")

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState({name: '', surname: '', email: '', phone: '', nip: ''});
    const [formErrors, setFormErrors] = useState({name: '', surname: '', email: '', nip: ''});
    const [inputTouched, setInputTouched] = useState({name: false, surname: false, email: false, nip: false});

    const {clearCart, cartItems} = useShoppingCart();

    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {

        const {name, value} = e.target;
        setInputTouched({ ...inputTouched, [name]: true });
        validateField(name, value);
        setFormData({ ...formData, [name]: value });
    };

    const validateField = (name: string, value: string) => {
        let errorMsg = '';
        switch (name) {
            case 'name':
                errorMsg = /^[a-zA-ZąĄęĘóÓłŁżŻźŹćĆśŚńŃ]+$/.test(value) ? '' : 'Imię może zawierać tylko litery (w tym polskie znaki).';
                break;
            case 'surname':
                errorMsg = /^[a-zA-ZąĄęĘóÓłŁżŻźŹćĆśŚńŃ]+(-[a-zA-ZąĄęĘóÓłŁżŻźŹćĆśŚńŃ]+)?$/.test(value) ? '' : 'Nazwisko może zawierać tylko litery i opcjonalnie jeden myślnik.';
                break;
            case 'email':
                errorMsg = /\S+@\S+\.\S+/.test(value) ? '' : 'Niepoprawny format adresu email.';
                break;
            case 'nip':
                errorMsg = /^\d{10}$/.test(value) ? '' : 'NIP musi składać się z 10 cyfr.';
                break;
        }
        setFormErrors({ ...formErrors, [name]: errorMsg });
    }

    const canSubmit = formData.name && formData.surname && formData.email && formData.nip && /\S+@\S+\.\S+/.test(formData.email);
    let {
        data,
        loading,
        error,
        sendPostRequest
    } = usePost<any>(process.env.REACT_APP_API_URL + '/api/v1/validations/list');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (canSubmit) {
            const payload = {
                ...formData,
                items: cartItems.map(({id, quantity}) => ({id, quantity}))
            }
            await sendPostRequest(payload);
            if (!error) {
                setIsSubmitted(true);
            }
        }
    };

    if (loading) {
        return <Loader loadingText={"Wysyłanie wiadomości..."}/>;
    }

    if (isSubmitted && !error) {
        clearCart();
        return (
            <div className="proceed-list-success">
                <CheckIcon id={"check-icon-proceed-list"}/>
                <h1>Dziękujemy za wysłanie listy!</h1>
                <p>Twoja lista cześci dotarła do nas. Skontaktujemy się z Tobą wkrótce.</p>
                <p>Kopia wiadomości została wysłana na Twój adres email: <strong>{formData.email}</strong></p>
                <Link to={"/"} id={"back-to-home"} className={"back-to-home-success"}>Wróć na stronę główną</Link>
            </div>
        );
    }

    if (cartItems.length === 0) {
        return (
            <div className={"no-items"}>
                <InfoCircledIcon id={"exclamation-icon"}/>
                <h1>Niestety w liście nie znajdują się żadne części</h1>
                <p>Wróć na stronę główną i wybierz interesującą Cię maszynę</p>
                <Link to={"/"} id={"back-to-home"}>Wróć na stronę główną</Link>
            </div>
        );
    }

    if (isSubmitted && error) {
        console.log(error);
        return (
            <div className="proceed-list-success">
                <CrossCircledIcon id={"error-icon-proceed-list"}/>
                <h1>Wystąpił błąd podczas wysyłania listy</h1>
                {error.code === "ERR_BAD_REQUEST" ?
                    <><p>Wystąpił błąd podczas przetwarzania Twojej listy.</p><p>{error.response.data.message}</p></>:
                    <p>Wystąpił błąd podczas przetwarzania Twojej listy. Spróbuj ponownie później</p>
                }
                <Link to={"/"} id={"back-to-home"} className={"back-to-home-error"}>Wróć na stronę główną</Link>
            </div>
        );
    }

    return (
        <div className="proceed-list-container wobis-row wobis-col2">
            <div className="form-container">
                <div className="page-title">
                    <h1>Wyślij listę</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-row wobis-row wobis-col2">
                        <div>
                            <input type="text" name="name" placeholder="Imię" value={formData.name}
                                   onChange={handleInputChange} required className={`input-field ${inputTouched.name ? (formErrors.name ? 'input-error' : 'input-success') : ''}`}/>
                            {inputTouched.name && formErrors.name && <span className="error-message">{formErrors.name}</span>}
                        </div>
                        <div>
                            <input type="text" name="surname" placeholder="Nazwisko" value={formData.surname}
                                   onChange={handleInputChange} required className={`input-field ${inputTouched.surname ? (formErrors.surname ? 'input-error' : 'input-success') : ''}`}/>
                            {inputTouched.surname && formErrors.surname && <span className="error-message">{formErrors.surname}</span>}
                        </div>
                    </div>
                    <div className="form-row">
                        <input type="text" pattern={"\\d{10}"} name="nip" placeholder="NIP" value={formData.nip}
                               onChange={handleInputChange} required className={`input-field ${inputTouched.nip ? (formErrors.nip ? 'input-error' : 'input-success') : ''}`}/>
                        {inputTouched.nip && formErrors.nip && <span className="error-message">{formErrors.nip}</span>}
                    </div>
                    <div className="form-row">
                        <input type="email" name="email" placeholder="Adres Email" value={formData.email}
                               onChange={handleInputChange} required className={`input-field ${inputTouched.email ? (formErrors.email ? 'input-error' : 'input-success') : ''}`}/>
                        {inputTouched.email && formErrors.email && <span className="error-message">{formErrors.email}</span>}
                    </div>
                    <div className="form-row">
                        <input type="tel" name="phone" placeholder="Numer Telefonu (opcjonalnie)" value={formData.phone}
                               onChange={handleInputChange}/>
                    </div>
                    <div className="form-row wobis-row">
                        <div className="form-checkbox">
                            <Checkbox.Root className="CheckboxRoot" required id={"list-rodo-agreement"}>
                                <Checkbox.Indicator className="CheckboxIndicator">
                                    <CheckIcon/>
                                </Checkbox.Indicator>
                            </Checkbox.Root>
                            <Label.Root htmlFor={"list-rodo-agreement"} className={"form-checkbox-label"}>
                                <span>Wyrażam zgodę na przetwarzanie moich danych osobowych w celu realizacji zamówienia.</span>
                            </Label.Root>
                        </div>
                    </div>
                    <div className="form-row wobis-row">
                        <div className="form-checkbox">
                            <Checkbox.Root className="CheckboxRoot" required id={"list-rodo-admin"}>
                                <Checkbox.Indicator className="CheckboxIndicator">
                                    <CheckIcon/>
                                </Checkbox.Indicator>
                            </Checkbox.Root>
                            <Label.Root htmlFor={"list-rodo-admin"} className={"form-checkbox-label"}>
                                <div id={"long-rodo-information"}>
                                    Administratorem danych osobowych jest WOBIS Maszyny Budowlane Wojtyczok Sp. K, NIP
                                    648 278 12 74 (dalej: „Administrator”).
                                    Pana/Pani dane będą przetwarzane w celu...<span className={"read-more"}><strong>Czytaj dalej</strong></span>
                                    <div className="hover-rodo">Administratorem danych osobowych jest WOBIS Maszyny
                                        Budowlane Wojtyczok Sp. K, NIP 648 278 12 74 (dalej: „Administrator”).
                                        Pana/Pani dane będą przetwarzane w celu prowadzenia korespondencji oraz w celach
                                        handlowych i marketingowych wynikających z treści przesłanego zapytania.
                                        Dane zostaną usunięte po odwołaniu zgody lub po zakończeniu prowadzenia działań
                                        marketingowych lub wysyłki informacji handlowych przez Administratora.
                                        Pana/Pani dane będą powierzane podmiotom trzecim na podstawie stosownych umów
                                        powierzenia przetwarzania danych osobowych w celu przechowywania
                                        danych osobowych na serwerze, skrzynce pocztowej oraz korzystania z usług
                                        wsparcia IT. Podstawą przetwarzania danych jest zgoda.
                                        W związku z przetwarzaniem danych osobowych ma Pan/Pani prawo do dostępu do
                                        swoich danych, sprostowania danych osobowych,
                                        usunięcia danych osobowych, wniesienia sprzeciwu, przenoszenia danych,
                                        ograniczenia przetwarzania, odwołania zgody, dostępu do informacji jakie
                                        dane Administrator przetwarza, złożenia skargi do Prezesa Urzędu Ochrony Danych
                                        Osobowych.
                                        Przedmiotowe uprawnienia można zrealizować poprzez kontakt z Administratorem na
                                        adres e-mail: krzysztofokula@wobis.pl.
                                        Pani/Pana dane nie będą przekazywane poza teren UE oraz nie będą wykorzystywane
                                        do zautomatyzowanego podejmowania decyzji ani profilowania.
                                        Administrator potrzebuje Pana/Pani Danych Osobowych aby zrealizować wskazany cel
                                        przetwarzania, podanie danych osobowych jest dobrowolne.
                                        W przypadku odwołania zgody, cele nie będą mogły być zrealizowane.
                                    </div>
                                </div>
                            </Label.Root>
                        </div>
                    </div>
                    <button type="submit" disabled={!canSubmit} className="proceed-button">
                        {loading ? "Wysyłanie listy..." : "Wysyłam darmową listę"}
                    </button>
                </form>
            </div>

        </div>
    );
};
