import React from "react";
import {useShoppingCart} from "../../utils/ListContext";
import {FileTextIcon, InfoCircledIcon, TrashIcon} from '@radix-ui/react-icons';
import ClearCartButton from "../../components/clearcartbutton/ClearCartButton";
import './ListPage.scss';
import {useDocumentTitle} from "../../utils/useDocumentTItle";
import {Link} from "react-router-dom";
import * as ScrollArea from "@radix-ui/react-scroll-area";

export const ListPage: React.FC = () => {

    useDocumentTitle('Lista części - Katalog części KUBOTA')
    const {cartItems, removeItem, handleExport, updateQuantity} = useShoppingCart();

    const handleRemoveItem = (id: number) => {
        removeItem(id);
    };

    if (cartItems.length === 0) {
        return (
            <div className={"no-items"}>
                <InfoCircledIcon id={"exclamation-icon"}/>
                <h1>Niestety w liście nie znajdują się żadne części</h1>
                <p>Wróć na stronę główną i wybierz interesującą Cię maszynę</p>
                <Link to={"/"} id={"back-to-home"}>Wróć na stronę główną</Link>
            </div>
        );
    }

    return (
        <div className="list-page-container">
            <div className="page-title">
                <h1>Lista części</h1>
            </div>
            <ScrollArea.Root className={"ScrollAreaRoot"}>
                <ScrollArea.Viewport className="ScrollAreaViewport">
                    <div className="cart-items-table">
                        <div className="cart-items-header">
                            <div>Numer części</div>
                            <div>Nazwa</div>
                            <div>Ilość</div>
                            <div>Akcje</div>
                        </div>
                        <div className="cart-table-content">
                            {cartItems.map(item => (
                                <div className="cart-item" key={item.id}>
                                    <div>{item.partNumber}</div>
                                    <div>{item.name}</div>
                                    <div className={"quantity-wrapper"}>
                                        <button
                                            className="quantity-button"
                                            onClick={() => updateQuantity(item.id, item.quantity - 1)}
                                            disabled={item.quantity <= 1}
                                        >-</button>
                                        <input type="number" value={item.quantity} readOnly className={"quantity-input"}/>
                                        <button
                                            className="quantity-button"
                                            onClick={() => updateQuantity(item.id, item.quantity + 1)}
                                            disabled={item.quantity >= 99}
                                        >+</button>
                                    </div>
                                    <div className="cart-item-remove-wrapper">
                                        <div className="cart-item-remove" onClick={() => handleRemoveItem(item.id)}>
                                            <TrashIcon/> Usuń
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
                    <ScrollArea.Thumb className="ScrollAreaThumb"/>
                </ScrollArea.Scrollbar>
                <ScrollArea.Corner className="ScrollAreaCorner"/>
            </ScrollArea.Root>
            <p className={"list-info"}>Wyślij listę części przez formularz po kliknięciu w przycisk "Przejdź dalej...", odeślemy darmową ofertę</p>
            <div className="cart-util-buttons">
                <ClearCartButton/>
            </div>
            <a href={"/przeslij-liste"} className="proceed-button" onClick={() => {/* Logika nawigacji do strony formularza */
            }}>
                Przejdź dalej i wyślij darmową listę
            </a>
        </div>
    );
};